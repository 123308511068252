// Vendors
// @import 'bootstrap';
@import 'nhsuk-frontend/packages/nhsuk';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Core
@import 'core/colors';
@import 'core/fonts';
@import 'core/variables';
@import 'core/buttons';
@import 'core/heading';
@import 'core/utils';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  height: 100%;
  font-family: $font-primary-regular, Arial;

  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  a {
    color: $color-nhsuk-blue;
    text-decoration: underline;
  }
}

p.nhsuk-error-color {
  color: $color-nhsuk-error;
}

.nhsuk-details,
.nhsuk-details[open] {
  .details-summary::before {
    border-left-color: initial;
    border-top-color: initial;
  }
}

legend.nhsuk-fieldset__legend,
label.nhsuk-label {
  margin-bottom: 10px;
}

.nhsuk-form-group {
  margin-bottom: 35px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
