@import './variables.scss';

.green {
  color: $color-primary-green;
}

.grey {
  color: $color-primary-grey;
}

.bck-orange {
  background-color: $color-primary-orange;
}

.bck-green {
  background-color: $color-primary-green;
}

.bck-grey {
  background-color: $color-primary-grey;
}

.bck-blue {
  background-color: $color-primary-blue;
}

.bck-dark-grey {
  background-color: $color-primary-dark-grey;
}

.bck-red {
  background-color: $color-primary-red;
}

.bck-yellow {
  background-color: $color-primary-yellow;
}
