@import 'scss/core/variables';

.nhsuk-header__link {
  max-width: 300px;
}
.nhsuk-logo {
  margin-bottom: 0.5rem;
}
.header-nhs-content {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: $color-primary-white;
  font-weight: 400;
  line-height: 24px;
  a.btn-login {
    background-color: transparent;
    border: 1px solid $color-primary-white;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin-right: 0;
    padding: 7px 16px;
    position: relative;
    text-decoration: none;
    z-index: 1;
    color: $color-primary-white !important;

    &:focus {
      background-color: #ffeb3b;
      color: #212b32 !important;
      outline: 4px solid transparent;
      outline-offset: 4px;
      box-shadow: 0 0 0 2px #ffeb3b, 0 4px 0 2px #212b32;
      border: none;
    }
  }
}

.nhsuk-image {
  width: 100% !important;
  margin: 0 !important;
}
