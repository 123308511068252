// #### COLORS #################################################################
$color-primary-white: #ffffff;
$color-full-black: #000000;
$color-primary-black: #212529;

$color-primary-green: #98c832;
$color-primary-green-opaced: rgba(152, 200, 50, 0.8);
$color-primary-green-shadow: rgba(152, 200, 50, 0.1);
$color-primary-green-strong: #8eb53a;
$color-primary-dark-grey: #666666;
$color-primary-grey: #d8d8d8;
$color-secondary-grey: #eeeeee;
$color-tertiary-grey: #7d7d7d;
$color-quaternary-grey: #eaeaea;
$color-quinary-grey: rgb(234, 234, 234);
$color-primary-purple: #6638cc;
$color-primary-orange: #ffbe2c;
$color-primary-blue: #0091ff;
$color-primary-red: #d8000c;
$color-primary-yellow: #ffd76b;
$color-gradient-green: rgb(156, 194, 23) 58%;
$color-gradient-yellow: rgb(245, 230, 30) 129%;
$color-full-black-shadow: rgba(0, 0, 0, 0.1);
$color-light-grey: #d8d8d8;
$color-units-grey: #999999;
$color-red: #d8000c;

$color-nhsuk-error: #d5281b;
$color-nhsuk-blue: #005eb8;

$gray-border: #d5d5d5;
$bg-white-light: #f8f8f8;
$bg-gray-light: #f3f3f3;

$gray-100: #f0f3f5;
$gray-200: #c2cfd6;
$gray-300: #a4b7c1;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #536c79;
$gray-700: #3e515b;
$gray-800: #29363d;
$gray-900: #151b1e;
$gray-light-span: #999999;
$gray-light-background: #fbfbfb;

// TEXT-AREA
$text-area-background: #fdfdfd;
$text-area-border: #979797;
$text-area-color: #666666;

// #### TYPOGRAPHY #############################################################
$font-primary-regular: 'Frutiger';
$font-primary-bold: 'FrutigerBold';

$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 20px;
$font-size-xxl: 24px;

// #### SPACING ################################################################
$navbar-brand-width: 200px !default;

// Sidebar

$sidebar-width: $navbar-brand-width;
$sidebar-padding: 0 !default;
$sidebar-minimized-width: 50px !default;
$sidebar-minimized-height: $sidebar-minimized-width !default;
$sidebar-compact-width: 150px !default;
$sidebar-compact-height: $sidebar-compact-width !default;
$sidebar-color: #fff !default;
// $sidebar-bg:                          $gray-800 !default;
$sidebar-bg: #024f54 !default;
$sidebar-borders: none !default;
$mobile-sidebar-width: 220px !default;

// Sidebar Header

$sidebar-header-height: auto !default;
$sidebar-header-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-header-padding-y: 0.75rem !default;
$sidebar-header-padding-x: 1rem !default;

// Sidebar Form

$sidebar-form-border: 0 !default;
$sidebar-form-bg: darken($sidebar-bg, 10%) !default;
$sidebar-form-color: #fff !default;
$sidebar-form-placeholder-color: rgba(255, 255, 255, 0.7) !default;

// Sidebar Navigation

$sidebar-nav-color: #fff !default;
$sidebar-nav-title-padding-y: 0.75rem !default;
$sidebar-nav-title-padding-x: 1rem !default;
$sidebar-nav-title-color: $gray-200 !default;
$sidebar-nav-link-padding-y: 0.75rem !default;
$sidebar-nav-link-padding-x: 1rem !default;
$sidebar-nav-link-color: #fff !default;
$sidebar-nav-link-bg: transparent !default;
$sidebar-nav-link-icon-color: $gray-600 !default;
$sidebar-nav-link-borders: 0 !default;

$sidebar-nav-link-hover-color: #fff !default;
$sidebar-nav-link-hover-bg: theme-color('primary') !default;
$sidebar-nav-link-hover-icon-color: #fff !default;
$sidebar-nav-link-hover-borders: 0 !default;

$sidebar-nav-link-active-color: $color-primary-black;
$sidebar-nav-link-active-bg: $color-primary-white;
$sidebar-nav-link-active-icon-color: theme-color('primary') !default;
$sidebar-nav-link-active-borders: 0 !default;

$sidebar-nav-dropdown-color: #fff !default;
$sidebar-nav-dropdown-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-nav-dropdown-borders: 0 !default;
$sidebar-nav-dropdown-indicator-color: $gray-600 !default;
$sidebar-nav-dropdown-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  '#',
  '%23'
) !default;
$sidebar-nav-dropdown-indicator-hover-color: $sidebar-nav-link-hover-color;
$sidebar-nav-dropdown-indicator-hover: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  '#',
  '%23'
) !default;

// Sidebar Footer

$sidebar-footer-height: auto !default;
$sidebar-footer-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-footer-padding-y: 0.75rem !default;
$sidebar-footer-padding-x: 1rem !default;
$sidebar-footer-borders: 0 !default;

// Sidebar Minimizer

$sidebar-minimizer-height: 50px !default;
$sidebar-minimizer-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-minimizer-borders: 0 !default;
$sidebar-minimizer-indicator-color: $gray-600 !default;
$sidebar-minimizer-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  '#',
  '%23'
) !default;
$sidebar-minimizer-hover-bg: rgba(0, 0, 0, 0.3) !default;
$sidebar-minimizer-hover-indicator-color: $sidebar-nav-link-hover-color !default;
$sidebar-minimizer-hover-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-hover-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  '#',
  '%23'
) !default;
