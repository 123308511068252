@font-face {
  font-family: Frutiger;
  src: local('Frutiger'), url('../../assets/fonts/frutiger/FrutigerRoman.woff2');
}

@font-face {
  font-family: FrutigerBold;
  src: local('FrutigerBold'),
    url('../../assets/fonts/frutiger/FrutigerBold.woff2');
}
