@import 'scss/core/variables';

a.btn-login {
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin-right: 0;
  padding: 12px 25px;
  position: relative;
  text-decoration: none;
  z-index: 1;
  color: $color-primary-white !important;
  background-color: $color-nhsuk-blue;
  width: fit-content;
  font-size: 18px;
  box-shadow: 0 4px 0 #1c3a57;

  &:focus {
    background-color: #ffeb3b;
    color: #212b32 !important;
    outline: 4px solid transparent;
    outline-offset: 4px;
    box-shadow: 0 0 0 2px #ffeb3b, 0 4px 0 2px #212b32;
    border: none;
  }
}
