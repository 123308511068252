.BaseLayout {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  .main {
    flex: 1;
    min-width: 0;
    overflow: auto;
  }
}

@media (max-width: 640px) {
  .nhsuk-width-container {
    margin: 0 !important;
  }
}
